<template lang="pug">
	.active-users(v-if="activeUsersNeurobot && activeUsersNeurobotGraph")
		.card-basic.active-users__card
			.d-flex.align-items-center
				span.b1 {{ $t('project.activeUsers') }}
				svg.ml-10.cursor-pointer(:id="!isMobile ? `tooltip-active-users` : ''" @click="isMobile ? $bvModal.show(`tooltip-active-users`) : ''" width='24' height='24' viewbox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
					circle(cx='12' cy='12' r='12' :fill="isDarkColor ? '#333' : '#F7F7F8'")
					path(d='M11.64 13.36C11.64 13.1573 11.6507 12.9707 11.672 12.8C11.704 12.6187 11.7627 12.4427 11.848 12.272C11.944 12.0907 12.072 11.904 12.232 11.712C12.4027 11.52 12.6267 11.3067 12.904 11.072C13.352 10.688 13.6933 10.3413 13.928 10.032C14.1627 9.72267 14.28 9.392 14.28 9.04C14.28 8.528 14.1093 8.10667 13.768 7.776C13.4267 7.44533 12.9573 7.28 12.36 7.28C11.7627 7.28 11.2933 7.44533 10.952 7.776C10.6107 8.10667 10.44 8.528 10.44 9.04H9C9 8.60267 9.07467 8.20267 9.224 7.84C9.384 7.46667 9.608 7.14667 9.896 6.88C10.1947 6.60267 10.552 6.38933 10.968 6.24C11.384 6.08 11.848 6 12.36 6C12.872 6 13.336 6.08 13.752 6.24C14.168 6.38933 14.52 6.60267 14.808 6.88C15.1067 7.14667 15.3307 7.46667 15.48 7.84C15.64 8.20267 15.72 8.60267 15.72 9.04C15.72 9.33867 15.672 9.62133 15.576 9.888C15.48 10.1547 15.3467 10.4107 15.176 10.656C15.016 10.8907 14.8293 11.12 14.616 11.344C14.4027 11.5573 14.184 11.76 13.96 11.952C13.7787 12.1013 13.6293 12.2453 13.512 12.384C13.3947 12.512 13.2987 12.64 13.224 12.768C13.16 12.8853 13.112 13.008 13.08 13.136C13.0587 13.2533 13.048 13.3813 13.048 13.52V13.84H11.64V13.36ZM12.36 17.52C12.0507 17.52 11.784 17.4133 11.56 17.2C11.3467 16.976 11.24 16.7093 11.24 16.4C11.24 16.08 11.3467 15.8133 11.56 15.6C11.784 15.3867 12.0507 15.28 12.36 15.28C12.6693 15.28 12.9307 15.3867 13.144 15.6C13.368 15.8133 13.48 16.08 13.48 16.4C13.48 16.7093 13.368 16.976 13.144 17.2C12.9307 17.4133 12.6693 17.52 12.36 17.52Z' fill='#52A7F9')

			.active-users__apexchart
				apexchart(type="line" height="206" :options="chartOptions" :series="series")
			
			.d-flex.flex-column.flex-md-row.justify-content-between.active-users__list
				.active-users__list-item(:class="!isMobile ? 'sub-stat__item' : ''")
					span.b3.text-center.text-grey(:class="!isMobile ? 'mb-1' : ''") {{ $t('date.yesterday') }}
					span.text-center(:class="!isMobile ? 'h3 text-bold' : 'b3'") {{ formatNumber(yesterdayUsers) }}
				.active-users__list-item(:class="!isMobile ? 'sub-stat__item' : ''")
					span.b3.text-center.text-grey(:class="!isMobile ? 'mb-1' : ''") {{ $t('date.sevenDays') }}
					span.text-center(:class="!isMobile ? 'h3 text-bold' : 'b3'") {{ formatNumber(weekUsers) }}
				.active-users__list-item(:class="!isMobile ? 'sub-stat__item' : ''")
					span.b3.text-center.text-grey(:class="!isMobile ? 'mb-1' : ''") {{ $t('date.thirtyDays') }}
					span.text-center(:class="!isMobile ? 'h3 text-bold' : 'b3'") {{ formatNumber(monthUsers) }}
		
		modal-tooltip(
			:id="`tooltip-active-users`"
			:classTooltip="'audience-tooltip'"
			:title="$t('project.activeUsers')"
			:text="$t('project.numberActiveUsers')"
			:textMobile="$t('project.numberActiveUsers')"
		)
	
</template>

<script>
import moment from 'moment-timezone';
import { mapActions, mapGetters } from 'vuex';
import ModalTooltip from '@/components/Modals/ModalTooltip';

export default {
    name: 'ActiveUsers',
    components: {
        ModalTooltip,
    },
    props: {
        id: {
            type: [String, Number],
            default: '0',
        },
    },
    data: () => ({
        dates: [],
        series: [],
        chartOptions: {},
        yesterdayUsers: '',
        weekUsers: '',
        monthUsers: '',
    }),
    computed: {
        ...mapGetters({
            activeUsersNeurobot: 'project/activeUsersNeurobot',
            activeUsersNeurobotGraph: 'project/activeUsersNeurobotGraph',
        }),
    },
    mounted() {
        this.getActiveUsers();
        this.getActiveUsersGraph();
    },
    methods: {
        ...mapActions({
            get: 'project/activeUsersNeurobot',
            getGraph: 'project/activeUsersNeurobotGraph',
        }),
        async getActiveUsers() {
            this.get({
                id: this.id,
            }).then(this.setActiveUsers);
        },
        async getActiveUsersGraph() {
            this.getGraph({
                id: this.id,
            }).then(this.setActiveUsersGraph);
        },
        setActiveUsers() {
            this.yesterdayUsers = this.activeUsersNeurobot.yest;
            this.weekUsers = this.activeUsersNeurobot.week;
            this.monthUsers = this.activeUsersNeurobot.month;
        },
        setActiveUsersGraph() {
            const results = this.activeUsersNeurobotGraph.results;

            this.getSeries(results);
            this.setChart();
            this.getDates(results);
        },
        getSeries(results) {
            const numberOfActivities = Object.values(results);

            this.series = [
                {
                    name: 'Active users',
                    data: numberOfActivities.slice(0, 7).reverse(),
                },
            ];
        },
        getDates(results) {
            const formattedDates = Object.keys(results).map(date =>
                moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM')
            );
            this.dates = formattedDates.slice(0, 7).reverse();
            this.chartOptions.xaxis.categories = this.dates.map(date => moment(date, 'DD.MM').format('DD.MM'));
        },
        setChart() {
            this.chartOptions = {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                grid: {
                    borderColor: this.isDarkColor ? '#444444' : '#E4E7EA',
                },
                colors: ['#52A7F9'],
                stroke: {
                    width: [1.5],
                },
                xaxis: {
                    tooltip: {
                        enabled: false,
                    },
                    crosshairs: {
                        show: true,
                        width: 2,
                        position: 'front',
                        stroke: {
                            width: 0,
                            dashArray: 0,
                        },
                        fill: {
                            color: this.isDarkColor ? '#444444' : '#E4E7EA',
                        },
                    },
                    categories: [],
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: true,
                        rotate: 0,
                        offsetY: this.isMobile ? -9 : 1,
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Golos, Nunito, sans-serif',
                            fontWeight: 400,
                            colors: this.isDarkColor ? '#EAEAEA' : '#082846',
                        },
                    },
                    axisBorder: {
                        color: this.isDarkColor ? '#858B8F' : '#CBD2D8',
                    },
                },
                yaxis: [
                    {
                        tickAmount: 2,
                        labels: {
                            show: true,
                            rotate: 0,
                            offsetX: -2,
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Golos, Nunito, sans-serif',
                                fontWeight: 400,
                                colors: this.isDarkColor ? '#808080' : '#8292A1',
                            },
                            formatter: value => {
                                if (value >= 1000) {
                                    return `${value / 1000}k`;
                                } else {
                                    return value.toFixed(0);
                                }
                            },
                        },
                    },
                ],
                toolbar: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
                legend: {
                    show: false,
                },
            };
        },
        formatNumber(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
    },
};
</script>

<style lang="scss">
.active-users {
    &__card {
        padding-top: 26px;

        @media (max-width: 767.98px) {
            padding-top: 14px;
            padding-bottom: 14px;
        }
    }

    &__apexchart {
        margin: 6px -28px 3px -15px;

        @media (max-width: 767.98px) {
            margin-top: -1px;
        }
    }

    &__list {
        &-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: calc(100% / 3 - 4px);
            background-color: var(--background-color);

            @media (max-width: 767.98px) {
                width: 100%;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                background-color: transparent;
                margin-bottom: 10px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
