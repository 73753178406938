<template lang="pug">
    .d-flex.flex-column.position-relative.card-basic.private-community(v-if="isShow")
        icon-close.private-community__close(@click="close")
        .d-flex
            img.private-community__img-mobile(v-if="isMobile" src="/assets/img/current/lock-mobile.webp" alt='lock')
            h3.h3.text-bold.private-community__title {{ $t('privateCommunity.title') }}
        p.b3.text-grey.private-community__txt {{ $t('privateCommunity.text') }}
        a.private-community__btn(:href="$t('contacts.private_club')" target="_blank") {{ $t('privateCommunity.button') }}
        img.private-community__img(v-if="!isMobile" src="/assets/img/current/lock.webp" alt='lock')
        .private-community__circle

</template>

<script>
import IconClose from '@/components/Common/IconClose';

export default {
    name: 'PrivateCommunity',
    components: {
        IconClose,
    },
    data: () => ({
        isShow: true,
    }),
    created() {
        this.isShow = !this.$cookies.get('cookies-community');
    },
    methods: {
        close() {
            this.isShow = false;
            this.$cookies.set('cookies-community', '1', '1Y');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.private-community {
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;

    &__title {
        @media (max-width: 575.98px) {
            margin-right: 26px !important;
        }
    }

    &__txt {
        max-width: 595px;
        margin-top: 15px;
        margin-bottom: 20px;

        @media (max-width: 575.98px) {
            margin-top: 10px;
        }
    }

    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 252px;
        border-radius: 8px;
        height: 50px;
        padding: 14px;
        color: var(--brand-primary);
        background-color: var(--brand-bg-primary);
        text-decoration: none;

        &:hover {
            color: var(--primary-dark-text-color);
        }

        &:active {
            color: var(--primary-dark-text-color);
            background-color: var(--primary-dark-bg-color);
        }

        @media (max-width: 575.98px) {
            max-width: 370px;
        }
    }

    &__img {
        position: absolute;
        bottom: 0;
        right: 20px;
        width: 133px;
    }

    &__img-mobile {
        width: 29px;
        height: 29px;
        margin-right: 4px;
    }

    &__circle {
        position: absolute;
        right: -118px;
        top: -244px;
        width: 406px;
        height: 407px;
        border-radius: 10000px;
        background: rgba(17, 200, 255, 0.17);
        filter: blur(61.0999984741211px);

        @media (max-width: 575.98px) {
            right: -59px;
            top: -104px;
            width: 206px;
            height: 207px;
        }
    }

    &__close {
        position: absolute;
        z-index: 1;
        top: 20px;
        right: 20px;
    }
}
</style>
